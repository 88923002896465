.btn_sign {
    background-image: -moz-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
}.header_text a:hover {
    background-image: -moz-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
}.con_btn a {
    background-image: -moz-linear-gradient( -13deg, rgb(68,39,232) 0%, rgb(0,163,236) 100%, rgb(68,39,232) 100%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( -13deg, rgb(68,39,232) 0%, rgb(0,163,236) 100%, rgb(68,39,232) 100%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( -13deg, rgb(68,39,232) 0%, rgb(0,163,236) 100%, rgb(68,39,232) 100%, rgb(68,39,232) 100%);
}.web_trafic .con_btn a:hover {
    background-image: -moz-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
}
.logo {
    width: 262px;
    height: 42px;
}
.logo_path {
    fill: #478eff;
}