body {
    font-family: 'Proxima Nova Rg';
    margin: 0;
    padding: 0;
}
.container {
    max-width: 1170px;
    margin: 0 auto;
}
.wrapper {
    width: 100%;
    height: 100%;
}
.hamburger {
    display: none;
    position: absolute;
    top: 30px;
    right: 20px;
    color: #fff;
    cursor: pointer;
    font-size: 34px;
}
header {
    background-image: url(../img/banner_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    height: 850px;
}
.no-webp header {
    background-image: url(../img/banner_bg.png);
}
header nav {
    padding: 35px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
nav ul {
    padding: 0;
    display: flex;
    list-style-type: none;
}
nav ul li {
    margin-left: 20px;
}
nav ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    position: relative;
}
nav ul li a::before,
footer p a::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    height: 1px;
    width: 0;
    transition: .35s all;
}
nav ul li a:hover::before,
footer p a:hover::before {
    width: 100%;
}
.btn_login {
    font-size: 16px;
    padding: 10px 23px;
    color: #1867b6;
    border-radius: 20px;
    background-color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 22px;
    transition: .35s all;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn_sign {
    margin-left: -11px;
    font-size: 16px;
    padding: 10px 20px;
    color: #fff;
    border-radius: 20px;
    background-image: -moz-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    text-transform: uppercase;
    font-weight: bold;
    transition: .35s all;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.btn_login:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    text-decoration: none;
}
.btn_login:hover::before {
    display: none;
}
.btn_sign:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    text-decoration: none;
}
.btn_sign:hover::before {
    display: none;
}
.btn_login i,
.btn_sign i {
    margin-right: 5px;
}
.header_text {
    margin-top: 145px;
    text-align: center;
    color: #fff;
}
.header_text h3 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 39px;
}
.header_text p {
    font-size: 24px;
}
.header_text a {
    display: inline-block;
    color: #1867b6;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    padding: 20px 37px;
    background-color: #fff;
    border-radius: 45px;
    margin-top: 23px;
    text-transform: uppercase;
    transition: .35s all;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.header_text a:hover {
    color: #fff;
    background-color: unset;
    background-image: -moz-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
}
.header_text span {
    margin-left: 7px;
    margin-right: 35px;
}
.conv_trafic {
    padding-top: 42px;
}
.conv_trafic h3,
.web_trafic h3,
.industry h3,
.global_brand h3,
.payment h3 {
    text-align: center;
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 55px;
}
.web_trafic h3 {
    color: #fff;
    margin-bottom: 87px;
}
.industry h3 {
    margin-top: 8px;
}
.global_brand h3 {
    color: #fff;
}
.payment h3 {
    margin-top: 24px;
    margin-bottom: 51px;
}
.conv_trafic_box {
    display: flex;
    justify-content: space-between;
}
.trafic_item {
    position: relative;
    width: 270px;
    height: 270px;
}
.trafic_item img {
    position: absolute;
    z-index: -1;
}
.item_info {
    color: #fff;
    padding: 17px;
    position: absolute;
    bottom: 0;
    margin-bottom: 13px;
    font-size: 18px;
}
.item_info i {
    color: #74d503;
    margin-right: 7px;
}
.item_info p {
    margin: 0;
    margin-top: 8px;
}
.item_info h4 {
    margin-bottom: 30px;
    font-size: 24px;
}
.con_btn {
    text-align: center;
    margin-top: 58px;
}
.con_btn a {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    background-image: -moz-linear-gradient( -13deg, rgb(68,39,232) 0%, rgb(0,163,236) 100%, rgb(68,39,232) 100%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( -13deg, rgb(68,39,232) 0%, rgb(0,163,236) 100%, rgb(68,39,232) 100%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( -13deg, rgb(68,39,232) 0%, rgb(0,163,236) 100%, rgb(68,39,232) 100%, rgb(68,39,232) 100%);
    color: #fff;
    padding: 20px 45px;
    border-radius: 45px;
    font-size: 18px;
    border: 1px solid #1867b6;
    transition: .35s all;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.con_btn a:hover {
    background-image: unset;
    background-image: unset;
    background-image: unset;
    border: 1px solid #1867b6;
    color: #1867b6;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
}
.web_trafic {
    background-image: url(../img/web_traffic_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 231px;
    padding-top: 183px;
}
.no-webp .web_trafic {
    background-image: url(../img/web_traffic_bg.png);
}
.web_trafic_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.web_trafic_item {
    text-align: center;
    width: 25%;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 86px;
}
.icon_list {
    box-sizing: border-box;
    width: 84px;
    height: 84px;
    margin: 0 auto;
    margin-bottom: 12px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.web_trafic .con_btn {
    margin-top: -9px;
}
.web_trafic .con_btn a {
    background-color: #fff;
    background-image: unset;
    background-image: unset;
    background-image: unset;
    color: #3a3ae9;
}
.web_trafic .con_btn a:hover {
    color: #fff;
    background-color: unset;
    background-image: -moz-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -webkit-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
    background-image: -ms-linear-gradient( 20deg, rgb(0,163,236) 0%, rgb(68,39,232) 100%);
}
.industry_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.industry_item {
    position: relative;
    width: 270px;
    height: 270px;
    margin-bottom: 30px;
}
.industry_item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 0%, #000000 100%);
    -webkit-box-shadow: 0px 0px 20px 0.9px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 0px 20px 0.9px rgba(0,0,0,0.33);
    box-shadow: 0px 0px 20px 0.9px rgba(0,0,0,0.33);
}
.industry_item img {
    position: absolute;
    z-index: -1;
}
.industry_item h4 {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #fff;
}
.global_brand {
    background-image: url(../img/blobal_brands_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 213px;
    padding-bottom: 261px;
    margin-top: 74px;
}
.no-webp .global_brand {
    background-image: url(../img/blobal_brands_bg.png);
}
.owl-carousel .owl-item img {
    width: unset;
}
.owl-item .item {
    width: 223px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.owl-carousel .owl-nav.disabled {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 28px;
}
.owl-prev,
.owl-prev:hover {
    margin-left: -85px !important;
    background: transparent;
    outline: none;
    color: black;
}
.owl-next,
.owl-next:hover {
    margin-right: -80px !important;
    background: transparent;
    outline: none;
    color: black;
}
.owl-theme .owl-nav [class*=owl-]:hover {
    background: unset;
    color: black;
    text-decoration: none;
}
.owl-prev span,
.owl-next span {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 45px;
    border-radius: 50%;
    background-color: #fff;
    outline: none;
    font-size: 35px;
}
.payment_item {
    border: 1px solid rgb( 58, 58, 233 );
    width: 300px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.payment_box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 39px;
}
footer {
    background-image: url(../img/footer_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 224px;
    margin-top: 34px;
    padding-bottom: 30px;
}
.no-webp footer {
    background-image: url(../img/footer_bg.png);
}
footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
footer p {
    color: #fff;
    font-size: 18px;
}
footer p a {
    color: #fff;
    text-decoration: none;
    transition: .35s all;
    display: inline-block;
    margin: 0 5px;
    position: relative;
}