/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('../fonts/ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Regular.woff') format('woff'),
		url('../fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('../fonts/ProximaNova-Semibold.eot');
	src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
		url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Semibold.woff') format('woff'),
		url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('../fonts/ProximaNova-Bold.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
		url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Bold.woff') format('woff'),
		url('../fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('../fonts/ProximaNova-Light.eot');
	src: local('Proxima Nova Light'), local('ProximaNova-Light'),
		url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-Light.woff') format('woff'),
		url('../fonts/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AvenirLTStd';
	src: url('../fonts/FontsFree-Net-AvenirLTStd-Book.ttf');
	src: local('FontsFree-Net-AvenirLTStd-Book'), local('FontsFree-Net-AvenirLTStd-Book'),
		url('../fonts/FontsFree-Net-AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FontsFree-Net-AvenirLTStd-Book.woff') format('woff'),
		url('../fonts/FontsFree-Net-AvenirLTStd-Book.ttf') format('truetype');
	font-style: normal;
	font-display: swap;
}