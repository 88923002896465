/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {  
    .conv_trafic_box {
        flex-wrap: wrap;
        justify-content: center;
    }
    .trafic_item {
        padding: 20px;
    }
    .industry_item {
        margin: 20px;
    }
    .industry_box {
        justify-content: center;
    }
    .owl-item .item {
        margin: auto;
        width: 198px;
        height: 109px;
    }
    .owl-theme .owl-dots {
        display: none;
    }
    footer .container {
        flex-direction: column;
        justify-content: center;
    }
    .header_text span {
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 5px;
        display: block;
    }
    .header_text p {
        padding-left: 50px;
        text-align: left;
    }
    header nav {
        padding: 35px 20px;
        display: block;
    }
    nav ul {
        display: none;
    }
    nav ul li {
        margin-left: unset;
    }
    nav ul li a {
        display: block;
        padding: 15px;
    }
    .btn_login,
    .btn_sign {
        margin-left: unset;
        text-align: center;
        margin-top: 15px;
    }
    .hamburger {
        display: block;
        top: 39px;
    }
    .web_trafic_box {
        justify-content: center;
    }
    .web_trafic_item {
        margin: 15px;
    }
    .conv_trafic h3, .web_trafic h3, .industry h3, .global_brand h3, .payment h3 {
        font-size: 35px;
    }
    .header_text h3 {
        font-size: 35px;
    }
    .header_text {
        margin-top: 68px;
    }
    header {
        background-position: -128px 0;
        background-size: 541%;
        height: auto;
        padding-bottom: 160px;
    }
    .header_text a {
        font-size: 16px;
        padding: 15px 20px;
    }
    .con_btn a {
        padding: 15px 35px;
        font-size: 16px;
    }
    .web_trafic {
        background-position: center;
        padding-bottom: 193px;
        padding-top: 138px;
    }
    .con_btn {
        margin-top: 15px;
    }
    .web_trafic h3 {
        margin-bottom: 35px;
    }
    .web_trafic .con_btn {
        margin-top: 20px;
    }
    .industry h3 {
        margin-top: -20px;
    }
    .global_brand {
        margin-top: 0;
        background-position: center;
        padding-top: 136px;
        padding-bottom: 165px;
    }
    footer {
        margin-top: 0;
        padding-bottom: 0;
        background-position: center;
        padding-top: 129px;
    }
    footer p {
        font-size: 15px;
        text-align: center;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {  
    .conv_trafic_box {
        flex-wrap: wrap;
        justify-content: center;
    }
    .trafic_item {
        padding: 20px;
    }
    .industry_item {
        margin: 20px;
    }
    .industry_box {
        justify-content: center;
    }
    .owl-item .item {
        margin: auto;
        width: 198px;
        height: 109px;
    }
    .owl-theme .owl-dots {
        display: none;
    }
    footer .container {
        flex-direction: column;
        justify-content: center;
    }
    .header_text span {
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 5px;
        display: block;
    }
    .header_text p {
        padding-left: 50px;
        text-align: left;
    }
    header nav {
        padding: 35px 20px;
        display: block;
    }
    nav ul {
        display: none;
    }
    nav ul li {
        margin-left: unset;
    }
    nav ul li a {
        display: block;
        padding: 15px;
    }
    .btn_login,
    .btn_sign {
        margin-left: unset;
        text-align: center;
        margin-top: 15px;
    }
    .hamburger {
        display: block;
        top: 47px;
        font-size: 20px;
    }
    .web_trafic_box {
        justify-content: center;
    }
    .web_trafic_item {
        margin: 15px;
    }
    .conv_trafic h3, .web_trafic h3, .industry h3, .global_brand h3, .payment h3 {
        font-size: 35px;
    }
    .header_text h3 {
        font-size: 35px;
    }
    .header_text {
        margin-top: 68px;
    }
    header {
        background-position: -128px 0;
        background-size: 541%;
        height: auto;
        padding-bottom: 160px;
    }
    .header_text a {
        font-size: 16px;
        padding: 15px 20px;
    }
    .con_btn a {
        padding: 15px 35px;
        font-size: 16px;
    }
    .web_trafic {
        background-position: center;
        padding-bottom: 193px;
        padding-top: 138px;
    }
    .con_btn {
        margin-top: 15px;
    }
    .web_trafic h3 {
        margin-bottom: 35px;
    }
    .web_trafic .con_btn {
        margin-top: 20px;
    }
    .industry h3 {
        margin-top: -20px;
    }
    .global_brand {
        margin-top: 0;
        background-position: center;
        padding-top: 136px;
        padding-bottom: 165px;
    }
    footer {
        margin-top: 0;
        padding-bottom: 0;
        background-position: center;
        padding-top: 129px;
    }
    footer p {
        font-size: 15px;
        text-align: center;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {  
    .conv_trafic_box {
        flex-wrap: wrap;
        justify-content: center;
    }
    .trafic_item {
        padding: 20px;
    }
    .industry_item {
        margin: 20px;
    }
    .industry_box {
        justify-content: center;
    }
    .owl-item .item {
        margin: 0 11px;
    }
    .owl-theme .owl-dots {
        display: none;
    }
    footer .container {
        flex-direction: column;
        justify-content: center;
    }
    .header_text span {
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 5px;
        display: block;
    }
    .header_text p {
        padding-left: 50px;
        text-align: left;
    }
    header nav {
        padding: 35px 20px;
        display: block;
    }
    nav ul {
        display: none;
    }
    nav ul li {
        margin-left: unset;
    }
    nav ul li a {
        display: block;
        padding: 15px;
    }
    .btn_login,
    .btn_sign {
        margin-left: unset;
        text-align: center;
        margin-top: 15px;
    }
    .hamburger {
        display: block;
    }
    .global_brand {
        margin-top: 0;
    }
    footer {
        margin-top: 0;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {  
    .owl-carousel .owl-nav.disabled {
        display: none;
    }
    .trafic_item {
        padding: 20px;
    }
    .conv_trafic_box {
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .industry_item {
        margin: 20px;
    }
    .industry_box {
        justify-content: center;
    }
    .owl-item .item {
        margin: 0 11px;
    }
    .owl-theme .owl-dots, .owl-theme .owl-nav {
        display: none;
    }
    nav ul {
        display: flex !important;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1340px) {  
    .owl-carousel .owl-nav.disabled {
        display: none;
    }
    nav ul {
        display: flex !important;
    }
}
@media (min-width: 1340px) {
    nav ul {
        display: flex !important;
    }
}